<template>
    <div class="data-container">
        <div class="data-head">{{ data && data.name }}</div>
        <el-row :gutter="20">
            <el-col :lg="6" class="group">
                <div class="box">
                    <div class="box-title">
                        <h4>核销票数统计</h4>
                    </div>
                    <div class="box-content MT20">
                        <div id="chart1"></div>
                        <div v-if="data" class="info">
                            <div class="num">{{ data.customerCount }} / <span>{{ data.totalSoldNum || 0 }}</span>
                            </div>
                            <div class="text">已核销 / 总票数</div>
                        </div>
                    </div>
                </div>
                <div class="box MT20">
                    <div class="box-title">
                        <h4>IC卡核销</h4>
                    </div>
                    <div class="box-content MT20">
                        <div id="chart2"></div>
                        <div v-if="data" class="info">
                            <div class="num">{{ data.staffCount }} / <span>{{ data.totalStaff || 0 }}</span>
                            </div>
                            <div class="text">已核销IC卡 / IC卡总数</div>
                        </div>
                    </div>
                </div>
                <div class="box list-box1 MT20">
                    <div class="box-title">
                        <h4>票品核销明细</h4>
                    </div>
                    <div class="list-wrap">
                        <div class="list-head">
                            <div class="tr">
                                <div class="th">票品</div>
                                <div class="th">已核销 / 票品总数</div>
                            </div>
                        </div>
                        <div class="list-content">
                            <div v-if="data && data.skuInfo" class="list-box">
                                <div v-for="(item, index) in data.skuInfo" :key="index" class="tr">
                                    <div class="td">{{ item.skuName }}</div>
                                    <div class="td">{{ item.checkNum }} / {{ item.soldNum }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- </el-col> -->
                <!-- <el-col :md="12">
                        <div class="group box box3">
                            <div class="box-title">
                                <h4>地域分布</h4>
                                <div class="desc">Geographical distribution</div>
                            </div>
                            <div id="chart2"></div>
                            <div class="list-wrap list-wrap-1">
                                <div class="tr">
                                    <div class="th">省份/直辖市</div>
                                    <div class="th">人数</div>
                                </div>
                                <div class="list-content" ref="listContent1">
                                    <vue-seamless-scroll v-if="scrollOption1 && chart2Data" :data="chart2Data"
                                        :class-option="scrollOption1" class="list-box">
                                        <div v-for="(item, index) in chart2Data" :key="index" class="tr">
                                            <div class="td">{{ item.name }}</div>
                                            <div class="td"><strong>{{ item.count }}</strong></div>
                                        </div>
                                    </vue-seamless-scroll>
                                    <div v-else class="list-box">
                                        <div v-for="(item, index) in chart2Data" :key="index" class="tr">
                                            <div class="td">{{ item.name }}</div>
                                            <div class="td"><strong>{{ item.count }}</strong></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-col> -->
            </el-col>
            <el-col :lg="12" class="group">
                <div class="box">
                    <div class="box-title">
                        <h4>时段核销</h4>
                        <div class="tab"><span :class="tabIndex == 1 ? 'current' : ''" @click="tabChange(1)">按时段</span>
                            | <span :class="tabIndex == 2 ? 'current' : ''" @click="tabChange(2)">按分钟</span></div>
                    </div>
                    <div id="chart3"></div>
                </div>
                <div class="box MT20">
                    <div class="box-title">
                        <h4>时段核销明细</h4>
                    </div>
                    <div class="data-group MT20">
                        <el-row v-if="data && data.timeFrameSta" :gutter="30">
                            <el-col :sm="8">
                                <div v-for="item in data.timeFrameSta.filter((v, i) => i < 8)" :key="item.name"
                                    class="data-item">
                                    <div>{{ item.name }}</div>
                                    <div>{{ item.count }}</div>
                                </div>
                            </el-col>
                            <el-col :sm="8">
                                <div v-for="item in data.timeFrameSta.filter((v, i) => i > 7 && i < 16)" :key="item.name"
                                    class="data-item">
                                    <div>{{ item.name }}</div>
                                    <div>{{ item.count }}</div>
                                </div>
                            </el-col>
                            <el-col :sm="8">
                                <div v-for="item in data.timeFrameSta.filter((v, i) => i > 15)" :key="item.name"
                                    class="data-item">
                                    <div>{{ item.name }}</div>
                                    <div>{{ item.count }}</div>
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                </div>
                <div class="box list-box2 MT20">
                    <div class="box-title">
                        <h4>核销明细</h4>
                        <div class="desc color-info">最新20条数据</div>
                    </div>
                    <div class="list-wrap">
                        <div class="list-head">
                            <div class="tr">
                                <div class="th">验票时间</div>
                                <div class="th">进场人</div>
                                <div class="th">票码</div>
                                <div class="th">验票设备</div>
                                <div class="th">设备终端</div>
                                <div class="th">验票方式</div>
                                <div class="th">工作人员</div>
                            </div>
                        </div>
                        <div class="list-content">
                            <div v-if="data && data.lastCheckInfo" class="list-box">
                                <div v-for="(item, index) in data.lastCheckInfo" :key="index" class="tr">
                                    <div class="td">{{ item.checkTime }}</div>
                                    <div class="td">{{ item.customerName }}</div>
                                    <div class="td">{{ item.ticketCode }}</div>
                                    <div class="td">{{ item.deviceTypeName }}</div>
                                    <div class="td">{{ item.deviceName }}</div>
                                    <div class="td">{{ item.checkTypeName }}</div>
                                    <div class="td">{{ item.staffName }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </el-col>
            <el-col :lg="6" class="group">
                <div class="box">
                    <div class="box-title">
                        <h4>入场通道</h4>
                    </div>
                    <div id="chart4"></div>
                </div>
                <div class="box list-box3 MT20">
                    <div class="box-title">
                        <h4>地域分布</h4>
                    </div>
                    <div id="chart5" class="MT20"></div>
                    <div class="list-wrap list-wrap3">
                        <div class="list-head">
                            <div class="tr">
                                <div class="th">省份/直辖市</div>
                                <div class="th">人数</div>
                            </div>
                        </div>
                        <div class="list-content">
                            <div v-if="chart5Data" class="list-box">
                                <div v-for="(item, index) in chart5Data" :key="index" class="tr">
                                    <div class="td">{{ item.name }}</div>
                                    <div class="td">{{ item.count }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import { Chart } from '@antv/g2';
import cookie from 'js-cookie'
export default {
    data() {
        return {
            dataLoading: null,
            duration: 60000,

            chart1: null,
            chart1Data: [],

            chart2: null,
            chart2Data: [],

            chart3: null,
            chart3Data: [],
            tabIndex: 1,

            chart4: null,
            chart4Data: [],

            chart5: null,
            chart5Data: [],

            data: null,

            timer: null
        };
    },
    created() {
        this.dataLoading = this.$loading({
            lock: true,
            text: '加载中...',
            background: 'rgba(225, 225, 225, 0.9)'
        });
    },
    mounted() {
        this.getData();
    },
    destroyed(){
        clearTimeout(this.timer);
        if( this.chart1 ){
            this.chart1.destroy();
            this.chart1 = null;
        }
        if( this.chart2 ){
            this.chart2.destroy();
            this.chart2 = null;
        }
        if( this.chart3 ){
            this.chart3.destroy();
            this.chart3 = null;
        }
        if( this.chart4 ){
            this.chart4.destroy();
            this.chart4 = null;
        }
        if( this.chart5 ){
            this.chart5.destroy();
            this.chart5 = null;
        }
    },
    methods: {
        //获取数据
        async getData() {

            const res = await this.$request({
                url: "/daping/stadata2",
                method: "POST",
                params: {
                    secretkey: cookie.get('ticketcheckSecretKey')
                },
            }).finally(() => {
                this.dataLoading.close();
            })

            const { state, msg, result } = res.data;
            if (state && result) {

                //核销票数统计
                this.chart1Data = [{ name: '核销票数统计', value: result.customerCount || 0, total: result.totalSoldNum > 0 ? result.totalSoldNum : 1 }];
                if (this.chart1) {
                    if( result.customerCount != this.data.customerCount ){
                        this.chart1.destroy();
                        this.chart1 = null;
                        this.drawChart1();
                    }
                }else{
                    this.drawChart1();
                }

                //IC卡核销
                this.chart2Data = [{ name: 'IC卡核销', value: result.staffCount || 0, total: result.totalStaff > 0 ? result.totalStaff : 1 }];
                if (this.chart2) {
                    if (result.staffCount != this.data.staffCount) {
                        this.chart2.destroy();
                        this.chart2 = null;
                        this.drawChart2();
                    }
                } else {
                    this.drawChart2();
                }

                //时段核销
                this.tabChange(this.tabIndex, result);

                // 入场通道
                this.chart4Data = result.entrySta ? result.entrySta : []
                if (this.chart4) {
                    this.chart4.changeData(this.chart4Data);
                } else {
                    this.drawChart4();
                }

                //地域分布
                this.chart5Data = result.areaSta ? result.areaSta : []
                if (this.chart5) {
                    this.chart5.changeData(this.chart5Data);
                } else {
                    this.drawChart5();
                }

                this.data = result;

            } else {
                if (state == 'daping.tologin') {
                    this.$router.replace('/Ticketcheck/login')
                } else {
                    this.$message.error(msg || '请求错误');
                }
            }

            this.timer = setTimeout(() => {
                this.getData();
                clearTimeout(this.timer);
            }, this.duration);
        },

        //绘制核销票数统计
        drawChart1() {
            this.chart1 = new Chart({
                container: 'chart1',
                autoFit: true,
                height: 110,
                width: 110
            });
            this.chart1.data(this.chart1Data);
            this.chart1.legend(false);
            this.chart1.tooltip(false);
            this.chart1.facet('rect', {
                fields: ['name'],
                padding: 0,
                showTitle: false,
                eachView: (view, facet) => {
                    let data = facet.data;
                    const color = "l(120) 0:#00FDEB 1:#3969FF";
                    data.push({ name: '未核销', value: data[0].total - data[0].value, total: data[0].total });
                    view.data(data);
                    view.coordinate('theta', {
                        radius: 1,
                        innerRadius: 0.5
                    });
                    view
                        .interval()
                        .adjust('stack')
                        .position('value')
                        .color('name', [color, '#0d3040'])
                        .style({
                            opacity: 1,
                        });
                    view.annotation().text({
                        position: ['50%', '50%'],
                        content: ((data[0].value / data[0].total) * 100).toFixed(0) + '%',
                        style: {
                            fontSize: 14,
                            fill: '#11D0F1',
                            fontWeight: 600,
                            textAlign: 'center'
                        },
                    });
                }
            });
            this.chart1.theme({
                styleSheet: {
                    backgroundColor: '#000000'
                }
            });
            this.chart1.render();
        },

        //IC卡核销
        drawChart2() {
            this.chart2 = new Chart({
                container: 'chart2',
                autoFit: true,
                height: 110,
                width: 110
            });
            this.chart2.data(this.chart2Data);
            this.chart2.legend(false);
            this.chart2.tooltip(false);
            this.chart2.facet('rect', {
                fields: ['name'],
                padding: 0,
                showTitle: false,
                eachView: (view, facet) => {
                    let data = facet.data;
                    const color = "l(120) 0:#FF99B4 1:#E451E4";
                    data.push({ name: '未核销', value: data[0].total - data[0].value, total: data[0].total });
                    view.data(data);
                    view.coordinate('theta', {
                        radius: 1,
                        innerRadius: 0.5
                    });
                    view
                        .interval()
                        .adjust('stack')
                        .position('value')
                        .color('name', [color, '#412234'])
                        .style({
                            opacity: 1,
                        });
                    view.annotation().text({
                        position: ['50%', '50%'],
                        content: ((data[0].value / data[0].total) * 100).toFixed(0) + '%',
                        style: {
                            fontSize: 14,
                            fill: '#E85EDB',
                            fontWeight: 600,
                            textAlign: 'center'
                        },
                    });
                }
            });
            this.chart2.theme({
                styleSheet: {
                    backgroundColor: '#000000'
                }
            });
            this.chart2.render();
        },

        //绘制时段核销
        drawChart3() {
            this.chart3 = new Chart({
                container: 'chart3',
                autoFit: true,
                height: 280,
                appendPadding: [20, 0, 10, 0],
                theme: {
                    styleSheet: {
                        backgroundColor: '#000000',
                    }
                },
                fill: 'l(270) 0:#ffffff 0.5:#7ec2f3 1:#1890ff',
            });
            this.chart3.data(this.chart3Data);

            this.chart3.scale({
                'name': {
                    range: [0, 1],
                    tickCount: 10,
                },
                'count': {
                    alias: '入场人数',
                    nice: true,
                }
            });

            this.chart3.axis('count', {
                grid: {
                    line: {
                        style: {
                            stroke: '#444444'
                        },
                    },
                },
            })
            this.chart3.axis('name', {
                line: {
                    style: {
                        stroke: '#444444'
                    },
                },
                tickLine: {
                    style: {
                        stroke: '#444444'
                    },
                }
            })

            this.chart3.tooltip({
                showCrosshairs: true,
                crosshairs: {
                    line: {
                        style: {
                            stroke: '#444444'
                        },
                    },
                }
            });

            this.chart3
                .line()
                .position('name*count')
                .style({
                    stroke: 'l(0) 0:#3969FF 0.5:#7ec2f3 1:#00FDEB',
                })
            // this.chart3.point().position('name*count').style({
            //     fill: "#000000"
            // });

            this.chart3.render();
        },
        //时段核销 切换
        tabChange(index, res) {
            this.tabIndex = index || 1;
            const data = res || this.data;
            if (this.tabIndex == 1) {
                this.chart3Data = data.timeFrameSta ? [...data.timeFrameSta] : [];
            } else {
                this.chart3Data = data.timeSta ? [...data.timeSta] : [];
            }
            if (this.chart3) {
                this.chart3.changeData(this.chart3Data);
            } else {
                this.drawChart3();
            }
        },

        // 入场通道
        drawChart4() {
            this.chart4 = new Chart({
                container: 'chart4',
                autoFit: true,
                appendPadding: [20, 0, 10, 0],
                height: 280
            });
            this.chart4.data(this.chart4Data);
            this.chart4.tooltip({
                showMarkers: false,
            });

            this.chart4.scale('count', {
                alias: '入场人数',
                nice: true,
            });

            this.chart4.axis('count', {
                grid: {
                    line: {
                        style: {
                            stroke: '#444444'
                        },
                    }
                }
            })
            this.chart4.axis('name', {
                line: {
                    style: {
                        stroke: '#444444'
                    },
                },
                tickLine: {
                    style: {
                        stroke: '#444444'
                    },
                }
            })

            this.chart4.interval()
                .position('name*count')
                .style({
                    fill: "l(90) 0:#00FDEB 0.5:#7ec2f3 1:#3969FF",
                    radius: [4, 4, 0, 0]
                })
                .label('count', {
                    style: {
                        fill: "#ffffff",
                    },
                    content: (originData) => {
                        return originData.count;
                    }
                }).size(50);
            this.chart4.render();
        },

        // 地域分布
        drawChart5() {
            this.chart5 = new Chart({
                container: 'chart5',
                autoFit: true,
                height: 240,
                appendPadding: [40, 0, 40, 0],
            });
            this.chart5.data(this.chart5Data);
            this.chart5.tooltip({
                showMarkers: false,
                showTitle: false,
            });
            this.chart5.legend(false);
            this.chart5.coordinate('theta', {
                // radius: 1,
                innerRadius: 0.55,
            });
            this.chart5.interval()
                .adjust('stack')
                .position('count')
                .color('name')
                .label('count', () => {
                    return {
                        style: {
                            fontSize: 10,
                        },
                        content: (originData) => {
                            return originData.name + ':' + originData.count;
                        }
                    }
                })
            this.chart5.render();
        }
    },
}
</script>
<style lang="scss" scoped>
.data-container {
    padding: 0 20px 0 20px;
    background: #242424;
    min-height: 100vh;

    .data-head {
        font-size: 24px;
        line-height: 1;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #000000;
        color: #fff;
        margin: 0 -20px 20px -20px;
        padding: 0 20px;
        text-align: center;
    }


    .group {
        height: calc(100vh - 120px);
        min-height: 1000px;
        margin-bottom: 20px;
    }

    .box {
        background: #000000;
        border-radius: 20px;
        overflow: hidden;
        padding: 20px;
        color: #fff;
        box-shadow: 0px 10px 40px 0px rgba(172, 174, 186, 0.1);

        .box-title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            line-height: 1;

            h4 {
                font-size: 16px;
                font-weight: 600;
            }

            .tab {
                color: #999999;
                font-weight: 600;

                span {
                    cursor: pointer;
                }

                .current {
                    color: #0670FC;
                }
            }
        }

        .box-content {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .info {
                text-align: center;

                .num {
                    font-size: 20px;
                    font-weight: 600;

                    span {
                        font-size: 14px;
                    }
                }

                .text {

                    opacity: 0.6;
                    font-size: 12px;
                }
            }
        }
    }


    .list-box1 {
        height: calc(100% - 186px - 186px - 20px - 20px);
    }

    .list-box2 {
        height: calc(100% - 336px - 332px - 20px - 20px);
    }
    .list-box3{
        height: calc(100% - 336px - 20px);
    }

    .list-wrap {
        margin-top: 16px;
        height: calc(100% - 20px - 16px);

        .tr {
            display: flex;
            justify-content: space-between;

            .th,
            .td {
                padding: 14px 0;
                font-size: 12px;
                line-height: 1;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .th {
                color: #999;
            }
        }

        .list-head {
            padding: 0 10px;
        }

        .list-content {
            position: relative;
            height: calc(100% - 40px);
            overflow-x: hidden;
            overflow-y: auto;
            padding: 0 20px;
            margin: 0 -10px;

            .list-box {}
        }
    }
    .list-wrap3{
        height: calc(100% - 240px - 16px - 16px - 20px);
    }

    .data-group {
        padding: 0 20px;

        .data-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 20px;
            font-size: 12px;
            height: 32px;
            border-radius: 4px;

            &:nth-child(odd) {
                background: #252525;
            }
        }
    }


    @media screen and (max-width: 768px) {
        .data-head {
            font-size: 16px;
            line-height: 1.2;
        }

        .group {
            height: auto;
            min-height: auto;
        }

        .box {
            padding: 20px 10px;
            overflow: visible;
        }

        .list-wrap {
            height: auto;
        }
    }

}
</style>